import { ProTableProps } from "@ant-design/pro-table";
import { Empty } from "antd";
import React from "react";

export const proTableSharedConfig: Partial<ProTableProps<any, any>> = {
  form: {
    layout: "vertical",
    autoFocusFirstInput: false,
  },
  rowKey: "id",
  columnEmptyText: "-",
  locale: {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  },
  options: {
    fullScreen: true,
    density: true,
    setting: true,
  },
  search: {
    layout: "vertical",
    searchText: "Hľadať",
    resetText: "Reset",
  },
  scroll: {
    x: 100,
  },
};

export interface GenerateSharedProTableConfig {
  overwrite?: Partial<ProTableProps<any, any>>;
  setFilters: (payload: any) => void;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
}
export const generateSharedTableConfig = (
  params: GenerateSharedProTableConfig
) => {
  return proTableSharedConfig;
};
