import { ProColumns } from "@ant-design/pro-table";
import { Skeleton } from "antd";
import React from "react";

export const makeLoadingTableColumns = (
  columns: ProColumns<any, "text">[]
): ProColumns<any, any>[] => {
  return columns.map((column) => {
    return {
      ...column,
      render: () => {
        return (
          <Skeleton
            key={column.key}
            title={{
              style: {
                margin: "3px 0",
                padding: 0,
                height: "10px",
              },
            }}
            paragraph={false}
          />
        );
      },
    };
  });
};

export const generatePlaceholderData = (rows) => {
  const result = [];
  for (let index = 0; index < rows; index++) {
    result.push({});
  }

  return result;
};

export const placeholderTableData = [
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];
