import { queryClient } from "@/lib/react-query/client";
import { useMySavedFiltersQuery, useSaveFilterMutation } from "@/lib/sdk/sdk";
import { SaveOutlined } from "@ant-design/icons";
import { ModalForm, ModalFormProps, ProFormText } from "@ant-design/pro-form";
import { Button, Tooltip } from "antd";
import React, { useMemo } from "react";

export interface SaveFiltersModalFormProps
  extends ModalFormProps<{ name: string }> {
  targetModelName: string;
  filters: any;
}

export const SaveFiltersModalForm = (props: SaveFiltersModalFormProps) => {
  const { className = "", filters, targetModelName } = props;

  const queryKey = useMemo(() => {
    return useMySavedFiltersQuery.getKey({
      where: {
        targetModelName,
      },
    });
  }, [targetModelName]);

  const { mutateAsync, isLoading } = useSaveFilterMutation({
    // when mutate is called:
    onMutate: async (newFilter) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousFilters = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (old: any) => {
        return {
          myFilters: [...old.myFilters, newFilter.input.data],
        };
      });

      // Return a context object with the snapshotted value
      return { previousFilters };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context: any) => {
      queryClient.setQueryData(queryKey, context.previousFilters);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return (
    <ModalForm<{ name: string }>
      title="Uložiť filter"
      trigger={
        <Tooltip
          title="Uložiť filter ako prepoužiteľný filter"
          mouseEnterDelay={1}
        >
          <Button
            type="dashed"
            loading={isLoading}
            icon={<SaveOutlined />}
          ></Button>
        </Tooltip>
      }
      modalProps={{
        destroyOnClose: true,
        width: "500px",
        onCancel: () => console.log("run"),
      }}
      onFinish={async (values) => {
        mutateAsync({
          input: {
            data: {
              name: values.name,
              targetModelName,
              filters,
            },
          },
        });
        return true;
      }}
      {...props}
    >
      <ProFormText
        name="name"
        label="Meno filtra"
        tooltip="Pod akým menom sa uloží tento filter"
        placeholder="napr. Moje faktúry"
      />
    </ModalForm>
  );
};
