import { TableFilter } from "@/lib/features/shared/model/table-filter-types";
import { transformTableFilters } from "@/lib/features/shared/utils/transformTableFilters.util";
import { PaginationConfig } from "antd/lib/pagination";
import { useMemo } from "react";
import { TableSorter } from "./useProTableControls/types";

export interface UseStrapiFilterQueryVarsParams {
  pagination: PaginationConfig;
  filters: any;
  sorter: TableSorter;
  filtersTransformer: (filters: object) => Record<string, TableFilter>;
}

export const useStrapiFilterQueryVars = (
  params: UseStrapiFilterQueryVarsParams
) => {
  const { pagination, filters, sorter, filtersTransformer } = params;

  return useMemo(() => {
    // Remove null values from filters
    let cleanFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != null)
    );

    return {
      limit: parseInt(pagination.pageSize as any),
      sort: `${sorter?.sortBy}:${sorter.direction}`,
      start:
        pagination.current === 1
          ? 0
          : (pagination.current - 1) * pagination.pageSize,
      where: transformTableFilters(filtersTransformer(cleanFilters)),
    };
  }, [pagination, filters, sorter]);
};
