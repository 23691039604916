export enum TableFilterTypes {
  Range = "Range",
  Search = "Search",
  Boolean = "Boolean",
  DateRange = "DateRange",
  SingleRelationship = "SingleRelationship",
  Raw = "Raw",
}

export interface TableFilter {
  type: TableFilterTypes;
  values: any;
}
