import { TableFilter, TableFilterTypes } from "../model/table-filter-types";

export const generateRangeStrapiFilter = (
  key: string,
  filter: TableFilter,
  strapiFilter: Object
) => {
  if (filter.values.from) strapiFilter[`${key}_gt`] = filter.values.from;
  if (filter.values.to) strapiFilter[`${key}_lt`] = filter.values.to;
};

export const generateDateRangeStrapiFilter = (
  key: string,
  filter: TableFilter,
  strapiFilter: Object
) => {
  try {
    strapiFilter[`${key}_gte`] = filter.values.range[0];
  } catch (e) {}
  try {
    strapiFilter[`${key}_lte`] = filter.values.range[1];
  } catch (e) {}
};

export const generateBooleanStrapiFilter = (
  key: string,
  filter: TableFilter,
  strapiFilter: Object
) => {};

export const generateSearchStrapiFilter = (
  key: string,
  filter: TableFilter,
  strapiFilter: Object
) => {
	if (filter.values.query === '') return;
  strapiFilter[`${key}_contains`] = filter.values.query;
};

export const generateSingleRelationshipStrapiFilter = (
  key: string,
  filter: TableFilter,
  strapiFilter: Object
) => {
  strapiFilter[`${key}`] = filter.values.relation;
};

export const generateRawStrapiFilter = (
  key: string,
  filter: TableFilter,
  strapiFilter: Object
) => {
  for (const filterKey of Object.keys(filter.values.value)) {
    strapiFilter[`${filterKey}`] = filter.values.value[filterKey];
  }
};

export const filterTypeStrapiFilterFunctionMap: Record<
  TableFilterTypes,
  (key: string, filter: TableFilter, strapiFilter: Object) => void
> = {
  [TableFilterTypes.Boolean]: generateBooleanStrapiFilter,
  [TableFilterTypes.Range]: generateRangeStrapiFilter,
  [TableFilterTypes.Search]: generateSearchStrapiFilter,
  [TableFilterTypes.DateRange]: generateDateRangeStrapiFilter,
  [TableFilterTypes.SingleRelationship]: generateSingleRelationshipStrapiFilter,
  [TableFilterTypes.Raw]: generateRawStrapiFilter,
};

/** Transforms ant design's table filters into strapi filter format */
export const transformTableFilters = (filters: Object) => {
  const strapiFilter = {};

  for (const key of Object.keys(filters)) {
    if (filters[key] == undefined) continue;
    if (typeof filters[key] === "object") {
      try {
        filterTypeStrapiFilterFunctionMap[filters[key].type](
          key,
          filters[key],
          strapiFilter
        );
      } catch (e) {
        console.log(e);
      }
    } else if (Array.isArray(filters[key])) {
      const [value] = filters[key];
      let filterObject: TableFilter;

      try {
        if (typeof JSON.parse(value) === "object") {
          filterObject = JSON.parse(value);
        }
      } catch (e) {}

      // This is in boolean and enumeration
      if (filterObject) {
        filterTypeStrapiFilterFunctionMap[filterObject.type](
          key,
          filterObject,
          strapiFilter
        );
      } else {
        if (!(key in strapiFilter)) strapiFilter[key] = [];
        for (const filterValue of filters[key]) {
          strapiFilter[key].push(filterValue);
        }
      }
    } else {
      strapiFilter[key] = filters[key];
    }
  }

  return strapiFilter;
};
