import { queryClient } from "@/lib/react-query/client";
import {
  SavedFilterFragmentFragment,
  useDeleteSavedFilterMutation,
  useMySavedFiltersQuery,
  useUpdateSavedFilterMutation,
} from "@/lib/sdk/sdk";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Menu, Space, Tooltip } from "antd";
import Dropdown, { DropdownButtonProps } from "antd/lib/dropdown";
import React, { useMemo } from "react";

export interface SavedFilterButtonProps extends Partial<DropdownButtonProps> {
  filter: SavedFilterFragmentFragment;
}

export const SavedFilterButton = React.memo(function SavedFilterButton(
  props: SavedFilterButtonProps
) {
  const { children, filter, ...restOfProps } = props;

  const modelFiltersQueryKey = useMemo(() => {
    return useMySavedFiltersQuery.getKey({
      where: {
        targetModelName: filter.targetModelName,
      },
    });
  }, [filter.targetModelName]);

  const { mutateAsync: updateFilter, isLoading: filterIsUpdating } =
    useUpdateSavedFilterMutation();

  const { mutateAsync: deleteFilter } = useDeleteSavedFilterMutation({
    onMutate: async (deletedFilter) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(modelFiltersQueryKey);

      // Snapshot the previous value
      const previousFilters = queryClient.getQueryData(modelFiltersQueryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(modelFiltersQueryKey, (old: any) => {
        return {
          myFilters: old.myFilters.filter(
            (filter) => filter.id !== deletedFilter.input.where.id
          ),
        };
      });

      // Return a context object with the snapshotted value
      return { previousFilters };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context: any) => {
      queryClient.setQueryData(modelFiltersQueryKey, context.previousFilters);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(modelFiltersQueryKey);
    },
  });

  const menu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item
          hidden
          key="2"
          onClick={() => {
            updateFilter({
              input: {
                where: {
                  id: filter.id,
                },
                data: {
                  filters: filter.filters,
                },
              },
            });
          }}
        >
          <Space className="flex items-center">
            <p>Aktualizovať</p>
            <Tooltip title="Aktualizovať tento filter na aktívny filter. Práve aktivny filter sa tak uloží pod týmto názvom.">
              <QuestionCircleOutlined className="flex items-center" />
            </Tooltip>
          </Space>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            deleteFilter({
              input: {
                where: {
                  id: filter.id,
                },
              },
            });
          }}
          danger
          key="3"
        >
          Vymazať
        </Menu.Item>
      </Menu>
    );
  }, [filter]);

  return (
    <Dropdown.Button overlay={menu} {...restOfProps}>
      {filter.name}
    </Dropdown.Button>
  );
});
