import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Box, BoxProps } from "@chakra-ui/react";
import { Badge, FormInstance, Space } from "antd";
import React, { useMemo } from "react";

export interface CollapseToggleProps extends BoxProps {
  form?: FormInstance;
  badge?: number;
  collapsed?: boolean;
}

export const CollapseToggle = (props: CollapseToggleProps) => {
  const { className = "", form, collapsed, badge = 0 } = props;

  const numberOfActiveFilters = useMemo(() => {
    return badge;
    if (!form) return 0;

    return Object.values(form.getFieldsValue()).filter((v) => {
      if (Array.isArray(v)) {
        return v.length > 0;
      }

      return !!v;
    }).length;
  }, [badge]);

  return (
    <Box {...props}>
      {collapsed ? (
        <Badge count={numberOfActiveFilters}>
          <Space>
            <span>Viac</span>
            <Box d="flex" alignItems="center">
              <DownOutlined />
            </Box>
          </Space>
        </Badge>
      ) : (
        <Badge count={numberOfActiveFilters}>
          <Space align="center">
            <span>Menej</span>
            <Box d="flex" alignItems="center">
              <UpOutlined />
            </Box>
          </Space>
        </Badge>
      )}
    </Box>
  );
};
