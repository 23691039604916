import { useMySavedFiltersQuery } from "@/lib/sdk/sdk";
import { Box, BoxProps } from "@chakra-ui/react";
import { Skeleton, Space } from "antd";
import React from "react";
import { SavedFilterButton } from "./components/SavedFilterButton";

export interface TableSavedFiltersToolbarProps extends BoxProps {
  targetModelName: string;
  onFilterSelected: (filters: any) => void;
}

const NUMBER_OF_SKELETON_BUTTONS = 10;

export const TableSavedFiltersToolbar = (
  props: TableSavedFiltersToolbarProps
) => {
  const { className = "", targetModelName, onFilterSelected } = props;

  const { data, isLoading } = useMySavedFiltersQuery({
    where: { targetModelName },
  });

  return (
    <Box {...props}>
      {isLoading && (
        <Space>
          {[...Array(NUMBER_OF_SKELETON_BUTTONS)].map((e, i) => (
            <Skeleton.Button active={isLoading} key={i} />
          ))}
        </Space>
      )}
      <Space wrap>
        {data &&
          data.myFilters.map((filter) => (
            <SavedFilterButton
              key={filter.id}
              onClick={() => {
                onFilterSelected(filter.filters);
              }}
              filter={filter}
            />
          ))}
      </Space>
    </Box>
  );
};
