import { SaveFiltersModalForm } from "@/lib/features/saved-filters/components/SaveFiltersModalForm/SaveFiltersModalForm";
import { TableSavedFiltersToolbar } from "@/lib/features/saved-filters/components/TableSavedFiltersToolbar/TableSavedFiltersToolbar";
import { CollapseToggle } from "@/lib/features/shared/components/CollapseToggle/CollapseToggle";
import { SearchConfig } from "@ant-design/pro-table/lib/components/Form/FormRender";
import { message, Space } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import React, { useCallback, useEffect, useState } from "react";
import { proTableSharedConfig } from "../../../config/pro-table-config";
import {
  TableSorter,
  UseProTableControlsParams,
  UseProTableControlsReturn,
} from "./types";

const getPaginationStateKey = (tableName: string) => {
  return `${tableName}.table.pagination`;
};

const getFiltersStateKey = (tableName: string) => {
  return `${tableName}.table.filters`;
};

const getSorterStateKey = (tableName: string) => {
  return `${tableName}.table.sorter`;
};

export function useProTableControls<T>(
  params: UseProTableControlsParams<T>
): UseProTableControlsReturn {
  const {
    searchFormRef,
    tableColumns,
    tableName,
    defaultFilters = {},
  } = params;

  /* ------------------------------- Pagination ------------------------------- */
  const [pagination, setPagination] = useState<PaginationConfig>(() => {
    const lsValue = localStorage.getItem(getPaginationStateKey(tableName));
    if (lsValue) {
      return JSON.parse(lsValue);
    }
    return {
      current: 1,
      pageSize: 20,
    };
  });

  const setPaginationPublic = useCallback(
    (params: PaginationConfig) => {
      localStorage.setItem(
        getPaginationStateKey(tableName),
        JSON.stringify(params)
      );
      setPagination((prev) => ({
        ...params,
      }));
    },
    [setPagination]
  );

  /* --------------------------------- Filters -------------------------------- */
  const [filters, setFilters] = useState(() => {
    // If there is a filters in localStorage, use it
    const lsValue = localStorage.getItem(getFiltersStateKey(tableName));
    if (lsValue) {
      return {
        ...JSON.parse(lsValue),
        ...defaultFilters,
      };
    }

    // Otherwise, generate a new filters object
    const defaultValues = {};
    for (const column of tableColumns) {
      defaultValues[column.key] = null;
    }
    return {
      ...defaultValues,
      ...defaultFilters,
    };
  });

  const setFiltersPublic = useCallback(
    (params: Partial<any>) => {
      localStorage.setItem(
        getFiltersStateKey(tableName),
        JSON.stringify(params)
      );
      setFilters((prev) => ({
        ...params,
      }));
    },
    [setFilters, defaultFilters]
  );

  /* --------------------------------- Sorter --------------------------------- */
  const [sorter, setSorter] = useState<TableSorter>(() => {
    const lsValue = localStorage.getItem(getSorterStateKey(tableName));
    if (lsValue) {
      return JSON.parse(lsValue);
    }
    return {
      sortBy: "id",
      direction: "desc",
    };
  });

  const setSorterPublic = useCallback(
    (params: TableSorter) => {
      localStorage.setItem(
        getSorterStateKey(tableName),
        JSON.stringify(params)
      );
      setSorter((prev) => ({
        ...params,
      }));
    },
    [setSorter]
  );

  /* ------------------------------- Config ------------------------------- */

  // At initialization fill the form with the filters from query
  useEffect(() => {
    if (!defaultFilters) return;
    setFilters(defaultFilters);
  }, []);

  // At initialization fill the form with the filters from query
  useEffect(() => {
    if (!searchFormRef?.current) return;

    searchFormRef.current.setFieldsValue({ ...filters });
  }, []);

  const [isSaveFiltersModalVisible, setIsSaveFiltersModalVisible] =
    useState(false);

  const defaultProps = React.useMemo(() => {
    return {
      ...proTableSharedConfig,
      pagination: {
        // Pagination object from the query params
        // ...pagination,
        onChange: (page, pageSize) => {
          setPaginationPublic({
            current: page,
            pageSize,
          });
        },
        // Constant config
        locale: {
          items_per_page: "/ strana",
        },
      },
      tableExtraRender: () => {
        return (
          <TableSavedFiltersToolbar
            targetModelName={tableName}
            onFilterSelected={(filters) => {
              searchFormRef.current.setFieldsValue(filters);
              setFiltersPublic(filters);
              message.success("Filter úspešne nastavený");
            }}
          />
        );
      },
      search: {
        optionRender: (searchConfig, formProps, dom = null) => {
          return [
            <>
              <Space>
                {dom}
                <SaveFiltersModalForm
                  targetModelName={tableName}
                  filters={
                    searchFormRef.current
                      ? searchFormRef.current.getFieldsValue(true)
                      : {}
                  }
                />
              </Space>
            </>,
          ];
        },
        collapseRender: (collapsed: boolean) => {
          return (
            <>
              <CollapseToggle
                badge={Object.keys(filters).length}
                form={searchFormRef.current}
                collapsed={collapsed}
              />
            </>
          );
        },
      } as SearchConfig,
    };
  }, [
    isSaveFiltersModalVisible,
    filters,
    searchFormRef.current,
    tableName,
    defaultFilters,
    setFilters,
  ]);

  /* -------------------------------- Callbacks ------------------------------- */
  const onTableChange = useCallback(
    (pagination: PaginationConfig, filters: any, newSorter: any) => {
      const sorterPayload: TableSorter = {
        sortBy: newSorter?.field || "id",
        direction: newSorter.order === "descend" ? "desc" : "asc",
      };

      if (
        sorterPayload.direction !== sorter.direction ||
        sorterPayload.sortBy !== sorter.sortBy
      ) {
        setSorter(sorterPayload);
      }
    },
    [setSorterPublic]
  );

  return {
    id: tableName,
    defaultProps,
    filters,
    setFilters: setFiltersPublic,
    pagination,
    setPagination: setPaginationPublic,
    sorter,
    setSorter: setSorterPublic,
    onTableChange,
  };
}
